import React from 'react'
import './schedule.css'
import Timeline from '../../components/Schedule/Timeline'
export const Schedulepage = () => {
  return (
    <div className='top h-[100vh]'>
      <div className='text-4xl'>
        <h1>Stay tuned, we'll be back soon..</h1>
      </div>
    </div>
  )
}
